import {Component, Input} from '@angular/core';
import {UserStockData} from "../model/UserStockData";
import {CurrencyPipe, NgForOf, NgIf} from "@angular/common";
import {SpinnerComponent} from "../../spinner/spinner.component";

@Component({
  selector: 'app-open-position',
  standalone: true,
  imports: [
    CurrencyPipe,
    SpinnerComponent,
    NgIf,
    NgForOf
  ],
  templateUrl: './open-position.component.html'
})
export class OpenPositionComponent {
  @Input() stockData: UserStockData | null = null;
}
