import {Component} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ApiClientService} from "../api-client/api-client.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ErrorDialogComponent} from "../error-dialog/error-dialog.component";

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.css'
})
export class LoginPageComponent {

  loginForm: FormGroup;
  errorMessage: string | null = null;

  constructor(private router: Router,
              private fb: FormBuilder,
              private apiClient: ApiClientService,
              private dialog: MatDialog ) {
    this.loginForm = this.fb.group({
      userId: ['', Validators.required],
      password: ['', Validators.required],
      server: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const { userId, password, server } = this.loginForm.value;
      const credentials = this.apiClient.buildCredentials(userId, password, server);
      this.apiClient.login(credentials).subscribe({
        next: (response) => {
          console.log('Login successful:', response);
          sessionStorage.setItem('credentials', credentials)
          this.router.navigate(['/dashboard']);
        },
        error: (error) => {
          console.error('Login failed:', error);
          this.openErrorDialog('Login failed. Please check your credentials and try again.'); // Open the error dialog
        }
      });
    } else {
      console.log('Form is invalid');
      this.loginForm.markAllAsTouched();
      this.openErrorDialog('Please fill out the form correctly.'); // Open dialog for form errors
    }
  }

  // Method to open the error dialog
  private openErrorDialog(message: string): void {
    this.dialog.open(ErrorDialogComponent, {
      data: { message } // Pass the error message to the dialog
    });
  }


}
