import {Component, Input} from '@angular/core';
import {UserStockData} from "../model/UserStockData";
import {CurrencyPipe, NgIf} from "@angular/common";
import {SpinnerComponent} from "../../spinner/spinner.component";

@Component({
  selector: 'app-account-balance',
  standalone: true,
  imports: [
    CurrencyPipe,
    NgIf,
    SpinnerComponent
  ],
  templateUrl: './account-balance.component.html'
})
export class AccountBalanceComponent {
  @Input() stockData: UserStockData | null = null;
}
