<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card shadow-sm">
        <div class="card-header text-center bg-primary text-white">
          <h4>Login</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <!-- User ID Field -->
            <div class="mb-3">
              <label for="userId" class="form-label">User ID</label>
              <input
                type="text"
                id="userId"
                formControlName="userId"
                class="form-control"
                placeholder="Enter User ID"
                required
              />
            </div>

            <!-- Password Field -->
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                id="password"
                formControlName="password"
                class="form-control"
                placeholder="Enter Password"
                required
              />
            </div>

            <!-- Server Options (Radio Buttons) -->
            <div class="mb-3">
              <label class="form-label">Server</label>
              <div class="form-check">
                <input
                  type="radio"
                  id="server-read"
                  formControlName="server"
                  value="READ"
                  class="form-check-input"
                />
                <label for="server-read" class="form-check-label">READ</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  id="server-demo"
                  formControlName="server"
                  value="DEMO"
                  class="form-check-input"
                />
                <label for="server-demo" class="form-check-label">DEMO</label>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="d-grid">
              <button type="submit" class="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
