import {Component, OnInit} from '@angular/core';
import {IndicatorData} from "../model/IndicatorData";
import {ApiClientService} from "../../api-client/api-client.service";
import {SpinnerComponent} from "../../spinner/spinner.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-predictions',
  standalone: true,
  imports: [
    SpinnerComponent,
    NgIf,
    NgForOf,
    NgClass
  ],
  templateUrl: './predictions.component.html'
})
export class PredictionsComponent implements OnInit {
  indicators: IndicatorData[] = [];
  sortedIndicators: IndicatorData[] = [];
  isLoading = true;
  sortOrder: 'asc' | 'desc' = 'asc';
  sortColumn: keyof IndicatorData | null = null;

  constructor(private apiClient: ApiClientService) {}

  ngOnInit(): void {
    this.fetchPredictions();
  }

  /**
   * Fetches prediction data from the API and initializes component state.
   */
  private fetchPredictions(): void {
    this.apiClient.indicatorData().subscribe({
      next: (data) => this.handleDataSuccess(data),
      error: (error) => this.handleDataError(error),
    });
  }

  /**
   * Handles successful data fetch by filtering and setting up initial state.
   * @param data - Array of `IndicatorData` received from the API.
   */
  private handleDataSuccess(data: IndicatorData[]): void {
    this.indicators = data.filter(item => item.actualPriceZScore != null);
    this.sortedIndicators = [...this.indicators];
    this.isLoading = false;
  }

  /**
   * Handles error during data fetch and logs the error.
   * @param error - Error object from the API call.
   */
  private handleDataError(error: any): void {
    console.error('Error fetching predictions data:', error);
    this.isLoading = false;
  }

  /**
   * Sorts the table by the specified column.
   * @param column - Column name to sort by.
   */
  sortTable(column: keyof IndicatorData): void {
    if (this.sortColumn === column) {
      this.sortOrder = this.toggleSortOrder();
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }

    this.applySorting();
  }

  /**
   * Toggles the sort order between ascending and descending.
   * @returns The new sort order.
   */
  private toggleSortOrder(): 'asc' | 'desc' {
    return this.sortOrder === 'asc' ? 'desc' : 'asc';
  }

  /**
   * Applies sorting to the indicators array based on the current column and order.
   */
  private applySorting(): void {
    if (!this.sortColumn) return;

    this.sortedIndicators = [...this.indicators].sort((a, b) => {
      const aValue = a[this.sortColumn!];
      const bValue = b[this.sortColumn!];

      if (aValue == null || bValue == null) return 0;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return this.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }

      return 0;
    });
  }
}
