<app-spinner *ngIf="!stockData"></app-spinner>

<div *ngIf="stockData" class="card shadow mb-5">
  <div class="card-header bg-success text-white">
    <h4 class="card-title mb-0">Open Stock Positions</h4>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-hover table-striped align-middle">
        <thead class="table-light">
        <tr>
          <th>Symbol</th>
          <th>Volume</th>
          <th>Open Price</th>
          <th>Close Price</th>
          <th>Profit</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let position of stockData?.allOpenedTrades">
          <td>{{ position.symbol }}</td>
          <td>{{ position.volume }}</td>
          <td>{{ position.open_price | currency }}</td>
          <td>{{ position.close_price | currency }}</td>
          <td [class.text-danger]="position.profit < 0" [class.text-success]="position.profit >= 0">
            {{ position.profit | currency }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
