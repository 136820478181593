import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserStockData} from "../dashboard/model/UserStockData";
import {environment} from "../../environments/environment";
import {IndicatorData} from "../dashboard/model/IndicatorData";

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  login(credentials: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Basic ${credentials}`
    });
    return this.http.get<any>(`${this.apiUrl}/user-stock`, { headers });
  }

  userStockData(): Observable<UserStockData> {
    const headers = new HttpHeaders({
      'Authorization': `Basic ${this.getCredentials()}`,
    });

    return this.http.get<UserStockData>(`${this.apiUrl}/user-stock`, { headers });
  }

  indicatorData(date: string = this.getCurrentDate()): Observable<IndicatorData[]> {
    const headers = new HttpHeaders({
      'Authorization': `Basic ${this.getCredentials()}`,
    });

    return this.http.get<IndicatorData[]>(`${this.apiUrl}/indicators/${date}`, { headers });
  }

  buildCredentials(userId: string, password: string, server: string): string {
    const decoded = `${userId}:${password}:${server}`;
    return btoa(decoded);
  }

  private getCredentials(): string {
    const credentials = sessionStorage.getItem('credentials');

    if (!credentials) {
      throw new Error('No credentials found');
    }

    return credentials;
  }

  private getCurrentDate(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

}
