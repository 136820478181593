<app-spinner *ngIf="isLoading"></app-spinner>

<div *ngIf="!isLoading && indicators" class="card shadow">
  <div class="card-header bg-info text-white">
    <h4 class="card-title mb-0">Predictions</h4>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-hover table-striped align-middle">
        <thead class="table-light">
        <tr>
          <th (click)="sortTable('symbol')">Symbol</th>
          <th (click)="sortTable('internalCategory')">Category</th>
          <th (click)="sortTable('actualPriceZScore')">Actual Z-Score</th>
          <th (click)="sortTable('avgPredictedPriceZScoreChange')">Avg Predicted Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeONE')">1 Day Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeTWO')">2 Days Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeTHREE')">3 Days Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeFIVE')">5 Days Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeEIGHT')">8 Days Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeTHIRTEEN')">13 Days Change</th>
          <th (click)="sortTable('predictedPriceZScoreChangeTWENTY_ONE')">21 Days Change</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let indicator of sortedIndicators">
          <td>{{ indicator.symbol }}</td>
          <td>{{ indicator.internalCategory }}</td>
          <td>{{ indicator.actualPriceZScore }}</td>
          <td>{{ indicator.avgPredictedPriceZScoreChange }}</td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeONE > 0, 'text-danger': indicator.predictedPriceZScoreChangeONE < 0}">
            {{ indicator.predictedPriceZScoreChangeONE }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeTWO > 0, 'text-danger': indicator.predictedPriceZScoreChangeTWO < 0}">
            {{ indicator.predictedPriceZScoreChangeTWO }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeTHREE > 0, 'text-danger': indicator.predictedPriceZScoreChangeTHREE < 0}">
            {{ indicator.predictedPriceZScoreChangeTHREE }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeFIVE > 0, 'text-danger': indicator.predictedPriceZScoreChangeFIVE < 0}">
            {{ indicator.predictedPriceZScoreChangeFIVE }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeEIGHT > 0, 'text-danger': indicator.predictedPriceZScoreChangeEIGHT < 0}">
            {{ indicator.predictedPriceZScoreChangeEIGHT }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeTHIRTEEN > 0, 'text-danger': indicator.predictedPriceZScoreChangeTHIRTEEN < 0}">
            {{ indicator.predictedPriceZScoreChangeTHIRTEEN }}
          </td>
          <td [ngClass]="{'text-success': indicator.predictedPriceZScoreChangeTWENTY_ONE > 0, 'text-danger': indicator.predictedPriceZScoreChangeTWENTY_ONE < 0}">
            {{ indicator.predictedPriceZScoreChangeTWENTY_ONE }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
