import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {UserStockData} from "./model/UserStockData";
import {ApiClientService} from "../api-client/api-client.service";
import {NavbarComponent} from "../navbar/navbar.component";
import {ErrorDialogComponent} from "../error-dialog/error-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AccountBalanceComponent} from "./account-balance/account-balance.component";
import {SpinnerComponent} from "../spinner/spinner.component";
import {OpenPositionComponent} from "./open-position/open-position.component";
import {PredictionsComponent} from "./predictions/predictions.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, NavbarComponent, AccountBalanceComponent, SpinnerComponent, OpenPositionComponent, PredictionsComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  stockData: UserStockData | null = null; // Initialize with null
  isLoading = true; // Loading state

  constructor(private apiClient: ApiClientService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadStockData();
  }

  loadStockData(): void {
    this.apiClient.userStockData().subscribe({
      next: (data) => {
        this.stockData = data; // Set stock data
        this.isLoading = false; // Data loaded
      },
      error: (error) => {
        console.error('Error fetching stock data:', error);
        this.openErrorDialog('Error fetching stock data:')
        this.isLoading = false; // Hide loading on error
      },
    });
  }

  // Method to open the error dialog
  private openErrorDialog(message: string): void {
    this.dialog.open(ErrorDialogComponent, {
      data: { message } // Pass the error message to the dialog
    });
  }

}
