import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {

  constructor(private router: Router) {} // Inject Router

  logout(): void {
    sessionStorage.removeItem('credentials'); // Clear session storage
    this.router.navigate(['/login']); // Redirect to login page
  }

}
