import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-error-dialog',
  standalone: true,
  imports: [],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.css'
})
export class ErrorDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    private dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {}

  ngOnInit(): void {
    // Automatically close the dialog
    setTimeout(() => {
      this.dialogRef.close();
    }, 2000);
  }

  onClose(): void {
    this.dialogRef.close(); // Close the dialog
  }

}
