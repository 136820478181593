<div class="container my-5">
  <app-menu></app-menu>
  <div class="text-center mb-5 pt-5">
    <h1 class="display-4">User Dashboard</h1>
    <p class="lead text-muted">Overview of your account balance and open stock positions</p>
  </div>
  <app-account-balance [stockData]="stockData"></app-account-balance>
  <app-open-position [stockData]="stockData"></app-open-position>
  <app-predictions></app-predictions>
</div>
