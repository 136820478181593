import {Routes} from '@angular/router';
import {LoginPageComponent} from "./login-page/login-page.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {authGuard} from "./auth-guard/auth.guard";

export const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: 'login', component: LoginPageComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [authGuard]}
];
