<app-spinner *ngIf="!stockData"></app-spinner>

<div *ngIf="stockData" class="card shadow mb-5">
  <div class="card-header bg-primary text-white">
    <h4 class="card-title mb-0">Account Balance</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <p><strong>Balance:</strong> {{ stockData?.balance?.balance | currency }}</p>
        <p><strong>Equity:</strong> {{ stockData?.balance?.equity | currency }}</p>
      </div>
      <div class="col-md-4">
        <p><strong>Margin:</strong> {{ stockData?.balance?.margin | currency }}</p>
        <p><strong>Margin Free:</strong> {{ stockData?.balance?.marginFree | currency }}</p>
      </div>
      <div class="col-md-4">
        <p><strong>Margin Level:</strong> {{ stockData?.balance?.marginLevel }}%</p>
        <p><strong>Credit:</strong> {{ stockData?.balance?.credit | currency }}</p>
      </div>
    </div>
  </div>
</div>
