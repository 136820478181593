<div class="container my-5">
  <!-- Menu Component -->
  <app-menu></app-menu>

  <!-- Header Section -->
  <div class="text-center mb-5 pt-5">
    <h1 class="display-4">User Dashboard</h1>
    <p class="lead text-muted">Overview of your account balance and open stock positions</p>
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="isLoading" class="text-center my-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Account Balance Section -->
  <div *ngIf="!isLoading && stockData" class="card shadow mb-5">
    <div class="card-header bg-primary text-white">
      <h4 class="card-title mb-0">Account Balance</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <p><strong>Balance:</strong> {{ stockData?.balance?.balance | currency }}</p>
          <p><strong>Equity:</strong> {{ stockData?.balance?.equity | currency }}</p>
        </div>
        <div class="col-md-4">
          <p><strong>Margin:</strong> {{ stockData?.balance?.margin | currency }}</p>
          <p><strong>Margin Free:</strong> {{ stockData?.balance?.marginFree | currency }}</p>
        </div>
        <div class="col-md-4">
          <p><strong>Margin Level:</strong> {{ stockData?.balance?.marginLevel }}%</p>
          <p><strong>Credit:</strong> {{ stockData?.balance?.credit | currency }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Open Stock Positions Section -->
  <div *ngIf="!isLoading && stockData" class="card shadow">
    <div class="card-header bg-success text-white">
      <h4 class="card-title mb-0">Open Stock Positions</h4>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover table-striped align-middle">
          <thead class="table-light">
          <tr>
            <th>Symbol</th>
            <th>Volume</th>
            <th>Open Price</th>
            <th>Close Price</th>
            <th>Profit</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let position of stockData?.allOpenedTrades">
            <td>{{ position.symbol }}</td>
            <td>{{ position.volume }}</td>
            <td>{{ position.open_price | currency }}</td>
            <td>{{ position.close_price | currency }}</td>
            <td [class.text-danger]="position.profit < 0" [class.text-success]="position.profit >= 0">
              {{ position.profit | currency }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
